.homepage-header {
  width: 100%;
  position: relative;

  .overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: flex-end;*/
    align-items: center;
    position: absolute;

    figure {
      height: 132px;
      img { height: 100%; }
    }
  }
}

.homepage-content {
  width: 100%;
  padding: 48px 16px 32px;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

#intro {
  width: fit-content;
  margin: 0 auto;

  > .container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    /*gap: 16px;*/
    column-gap: 16px;
    row-gap: 24px;

    figure {
      margin: 0;
      img {
        width: 250px;
        box-shadow: 0 5px 15px 0 rgba(0,0,0,0.15);
      }
    }
  }
}

.intro-content {
  display: flex;
  flex-direction: column;
  max-width: 450px;

  h1 {
    margin: 0;
    font-size: 2.75em;
  }
  .socials { align-self: flex-end; }
}

#contact {
  margin: 0 auto;
}

@media screen and (min-width: $breakpoint__t) {
  .homepage-content { padding: 64px 24px 32px; }
  #intro > .container {
    justify-content: space-between;
  }
}

@media screen and (min-width: $breakpoint__d) {
  .homepage-content { padding: 64px 80px 32px; }
}
