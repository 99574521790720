#projects { width: 100%; }

.projects-header {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0 32px;
  column-gap: 64px;
  row-gap: 24px;

  h2 {
    font-size: 2.5em;
    margin: 0;
  }

  p {
    font-size: 1.75em;
    margin: 0;
    flex: 3;
  }
}

.project-thumbnail {
  width: 100%;
  margin: 0;

  img { width: 100%; }
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 72px;
  width: 100%;
}

.project {
  display: flex;
  width: 100%;
  row-gap: 16px;
  column-gap: 24px;
  flex-wrap: wrap;
}

.project-content {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;

  > div:first-of-type {
    /*width: 30%;*/
    flex: 1;
    display: flex;
    /*flex-wrap: wrap;*/
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .project-title { flex-grow: 1; }
    .project-platform { /*align-self: flex-end;*/ }
  }
  > div:last-of-type {
    /*width: 70%;*/
    flex: 2;
    p { margin: 0; }
  }
}

.project-role { font-size: 0.75em; }
.project-title {
  font-size: 1.5em;
  a { color: $color__text; }
}
.project-platform { font-size: 0.875em; }

@media screen and (min-width: $breakpoint__xs) {
}

@media screen and (min-width: $breakpoint__m) {
  .projects-header h2 { font-size: 2.75em; }
}

@media screen and (min-width: $breakpoint__t) {
}

@media screen and (min-width: $breakpoint__d) {
  .project-thumbnail {
    flex: 7;
    order: 1;
  }

  .project-content {
    flex: 3;

    > div:last-of-type {
      flex: unset;
    }
  }
}
