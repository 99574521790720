.socials {
  @extend %list;
  display: flex;
  gap: 12px;
  font-size: 1.75em;

  a {
    color: $color__text;
    text-decoration: none;
  }
  .socials-email { color: $color__text-gold; }
}
