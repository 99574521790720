.site-header {
  width: 100%;
  /*height: 90px;*/
  height: 72px;
  top: 0;
  z-index: 100;
  box-sizing: border-box;
  position: sticky;
  background-color: $color__background-body;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 16px;
    box-sizing: border-box;
    border-bottom: solid 2px $color__text;
  }
}

.site-brand-logo { height: 64px; transition: all 0.3s ease-in-out; }

.site-header-homepage {
  position: fixed;
  background-color: transparent;
  height: 45px;

  .container {
    justify-content: flex-end;
    border: none;
  }
  #site-nav-toggle { color: $color__background-body; }

  .site-brand-logo { display: none; }
}


@media screen and (min-width: $breakpoint__t) {
  .site-header {
    padding: 0 24px;
    .container { padding: 0; }
  }

  .site-header-homepage .site-nav > ul a { color: $color__background-body; }
}

@media screen and (min-width: $breakpoint__d) {
  .site-header { padding: 0 80px; }
}
