.site-nav > ul {
  @extend %list;
  display: flex;
  gap: 12px;

  a {
    color: $color__text;
    text-decoration: none;
  }
}

#site-nav-toggle { font-size: 1.75em; }

#wrapper { position: relative; }

@media screen and (min-width: $breakpoint__d) {
  #site-nav-toggle { display: none; }
  .site-nav { display: block !important; }
  /* temp! */
  #wrapper > .overlay { display: none; }
}

@media screen and (max-width: $breakpoint__d) {
  .site-nav {
    /* Mobile Site Nav */
    width: 100%;
    box-sizing: border-box;
    background-color: $color__background-body;
    position: absolute;
    left: 0;
    top: 100%;
    padding: 12px 24px 8px;

    ul {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  /* temp! */
  #wrapper > .overlay {
    z-index: 99;
    background-color: hsla(0, 0%, 0%, 0.5);
    transition: all 0.3s ease-in-out;
  }
}
