@import "homepage/homepage";

.container { width: 100%; }

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: $color__background-body;
  background-color: hsla(0, 0%, 0%, 0.15);
}

.video-container video {
  display: block;
  width: 100%;
}

.page {
  width: 100%;
}
