#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .arrow {
    font-size: 2.25em;
    color: $color__text-gold;
  }
}

.contact-header {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 12px;
  width: 100%;

  h2 {
    display: table-caption;
    font-size: 1.75em;
    margin: 0;
    width: min-content;
  }

  figure {
    width: 70%;
    margin: 0;
    img { width: 100%; }
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 80%;
  max-width: 520px;

  label { display: none; }
  .contact-form-submit { align-self: flex-end; }
}

.contact-form-field, .contact-form-textarea {
  width: 100%;
  border: none;
  box-sizing: border-box;
  padding: 3px 3px 3px 12px;
  font-size: 0.75em;
}

.contact-form-field { height: 30px; }

.contact-form-textarea {
  height: 180px;
  padding-top: 6px;
}

.contact-form-submit {
  padding: 8px 6px;
  border: none;
  color: $color__text-gold;
  background-color: $color__gold;
  box-shadow: 4px 4px $color__text-gold;
  text-transform: uppercase;
  font-size: 0.875em;
}

@media screen and (min-width: $breakpoint__m) {
  .contact-header {
    h2 { font-size: 2em; }
    /*figure img { width: 240px; }*/
  }
}

@media screen and (min-width: $breakpoint__t) {
  .contact-header {
    h2 { font-size: 3em; }
  }

  .contact-form-field { height: 40px; }
  .contact-form-textarea {
    height: 240px;
    padding-top: 8px;
  }
  .contact-form-submit { font-size: 1em; }
}
