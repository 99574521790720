@import "variables/variables";

body {
  font-family: 'Noto Sans', sans-serif;
  color: $color__text;
  background-color: $color__background-body;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Noto Serif', serif;
}

.container-fluid {
  width: 100%;
}

.link {
  color: $color__text-gold;
  font-weight: bold;
  text-decoration: none;
}

hr.hr {
  width: 100%;
  padding: 0;
  border: none;
  border-top: medium double $color__text;
  color: $color__text;
  text-align: center;
}
hr.hr:after {
  content: "§";
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
  background: $color__background-body;
}
