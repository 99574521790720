.site-footer {
  padding: 48px 32px 16px;
  margin: 0;
  color: $color__background-body;
  background-color: $color__text;

  .container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .socials {
    a { color: $color__background-body; }
    .socials-email { color: $color__gold; }
  }
}

.footer-nav > ul {
  @extend %list;
  display: flex;
  flex-direction: column;
  gap: 12px;

  a {
    color: $color__background-body;
    text-decoration: none;
  }
}

.site-footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-footer-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.site-copyright {
  text-transform: uppercase;
  font-size: 0.75em;
  opacity: 0.85;
}

.footer-logo {
  margin: 0;
  img { height: 116px; }
}
